<template>
  <div class="leave_container">
    <div class="info">
      <ul>
        <li class="title">{{ info.groupName }}</li>
        <li>
          <span><img src="../../../assets/images/medal.png" alt="" /></span>
          <div class="personal">
            {{ info.studentName }}
          </div>
        </li>
        <li>
          <span><img src="../../../assets/images/clock.png" alt="" /></span>
          {{ info.startTime ? info.startTime.substring(11, 16) : "" }}-{{
            info.endTime ? info.endTime.substring(11, 16) : ""
          }}（当前第{{ info.classedNum }}节/共{{ info.classTotal }}节）
        </li>
        <!-- <li>
          <span></span>
          2022-01-01 周一
        </li> -->
        <li class="approver">
          <p>审批人</p>
          <p>排课班主任</p>
        </li>
      </ul>
    </div>
    <div class="leave_form">
      <ul>
        <li>
          <span>课时调整</span>
          <div>
            <button
              :class="dataForm.operationType == 1 ? 'clickStyle' : ''"
              :disabled="info.courseType == 1"
              v-if="info.courseType != 1"
              @click="typeChange(1)"
            >
              请假并改期</button
            ><button
              :class="{
                clickStyle: dataForm.operationType != 1,
                one: info.courseType == 1,
              }"
              @click="typeChange(2)"
            >
              请假
            </button>
          </div>
        </li>
        <li>
          <span></span>
          <div class="key">
            {{
              dataForm.operationType == 2
                ? "暂时取消这节课的上课安排"
                : "将这节课改至其他时间上课"
            }}
          </div>
        </li>
        <li @click="popopShow" v-show="dataForm.operationType == 1">
          <span>新上课时间<span class="star">*</span></span>
          <div :class="{ calendar: true, timeColor: time }">
            <span v-show="dataForm.newStartTime" style="display: flex"
              ><span style="min-width: 76px">{{ dataForm.newDt }}</span
              ><span style="margin: 0 2px">{{ time }}</span
              ><span></span
              >{{
                dataForm.newStartTime.substring(0, 5) +
                " - " +
                dataForm.newEndTime.substring(0, 5)
              }}</span
            >
            <span v-show="!dataForm.newStartTime">查看并选择时间</span>
            <img src="../../../assets/images/calendar_icon.png" alt="" />
          </div>
        </li>
        <li style="align-items: initial">
          <span>原因说明<span class="star">*</span></span>
          <textarea
            maxlength="200"
            :disabled="dataForm.approvalStatus == 0"
            v-model="dataForm.reason"
          ></textarea>
        </li>
      </ul>
    </div>
    <button :class="{ submit_btn: true, complete: complete }" @click="submit">
      {{
        dataForm.approvalStatus == -1 || dataForm.approvalStatus == 1
          ? "提交申请"
          : dataForm.approvalStatus == 0
          ? "撤回"
          : "重新提交"
      }}
    </button>
    <van-popup v-model="show" position="bottom" class="popup">
      <h3>学员空闲参考时间</h3>
      <div class="timetable" v-if="timeList.length">
        <div class="timetable_list">
          <div
            :class="{ item: true, item_click: index === num }"
            v-for="(i, index) in timeList"
            :key="index"
            @click="itemClick(i, index)"
          >
            <span>{{ i.singleDate }}</span
            ><span>{{ DayWeek[i.dayForWeek - 1] }}</span
            ><span>{{
              i.startHour.substring(0, 5) + " - " + i.endHour.substring(0, 5)
            }}</span>
          </div>
        </div>
        <p>仅展示下节课之前且最近一周内的空闲时间</p>
        <div class="btn_container">
          <div @click="goBack('返回')">返回</div>
          <div @click="goBack('确定')" class="determine_btn">确定</div>
        </div>
      </div>
      <div class="noMore" v-else>
        <div>暂无可选择的时间可提供调整课时 可以直接请假</div>
        <button class="pop_button" @click="goBack('返回')">我知道了</button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  modifyFiling,
  modifyFreeTime,
  plan,
  modifyDetail,
  modifyRecall,
  modifyReSubmit,
} from "../../../api/mentor";
import { DayWeek } from "../../../api/constant";
import { Dialog } from "vant";
import dayjs from "dayjs";
export default {
  data() {
    return {
      show: false,
      num: "",
      time: "",
      reason: "",
      timeList: [],
      info: {},
      dataForm: {
        planId: this.$route.params.planId,
        operationType: 1,
        reason: "",
        newDt: "",
        newStartTime: "",
        newEndTime: "",
      },
      DayWeek,
      duration: "",
      clickItme: "",
      startTime: "",
    };
  },
  methods: {
    typeChange(type) {
      this.dataForm.operationType = type;
    },
    popopShow() {
      this.show = true;
    },
    itemClick(i, index) {
      // this.dataForm.newDt = i.singleDate;
      // this.dataForm.newStartTime = i.startHour;
      // this.dataForm.newEndTime = i.endHour;
      // this.time = DayWeek[i.dayForWeek - 1];
      this.clickItme = i;
      this.num = index;
    },
    goBack(type) {
      if (type !== "确定") {
        this.show = false;
      } else {
        if (this.clickItme) {
          this.dataForm.newDt = this.clickItme.singleDate;
          this.dataForm.newStartTime = this.clickItme.startHour;
          this.dataForm.newEndTime = this.clickItme.endHour;
          this.time = DayWeek[this.clickItme.dayForWeek - 1];
          this.show = false;
        } else {
          return this.$toast("选一个新的上课时间吧");
        }
      }
    },
    // 空闲时间列表
    async getModifyFreeTime() {
      const res = await modifyFreeTime(this.$route.params.planId);
      this.duration = res.duration;
      if (res.freeTimeList) {
        this.timeList = res.freeTimeList;
      }
    },
    // 课时详情
    async getPlan() {
      const res = await plan(this.$route.params.planId);
      this.info = res;
      this.startTime = res.startTime;
      this.info.courseType == 1
        ? (this.dataForm.operationType = 2)
        : (this.dataForm.operationType = 1);
      this.$forceUpdate();
    },
    submit() {
      console.log(this.dataForm.approvalStatus);
      if (this.dataForm.approvalStatus == 0) {
        Dialog.confirm({
          title: "提示",
          message: "是否撤回申请",
        })
          .then(() => {
            this.modifyRecall(this.dataForm.id);
          })
          .catch(() => {
            // on cancel
          });
      } else {
        if (this.complete) {
          console.log(this.startTime);
          let date = new Date(this.startTime).getTime() - new Date().getTime();
          let m = parseInt((date % (1000 * 60 * 60 * 24)) / (1000 * 60));
          console.log(date, m);
          if (m < 0) {
            return this.$toast("已经超过上课开始时间，无法请假");
          }
          if (m < 120) {
            Dialog.confirm({
              title: "提示",
              message: "是否提交申请",
              cancelButtonText: "我再想想",
            })
              .then(() => {
                if (
                  this.dataForm.approvalStatus == -1 ||
                  this.dataForm.approvalStatus == 1
                ) {
                  this.modifyFiling();
                } else {
                  this.modifyReSubmit();
                }
              })
              .catch(() => {
                // on cancel
              });
          } else {
            if (
              this.dataForm.approvalStatus == -1 ||
              this.dataForm.approvalStatus == 1
            ) {
              this.modifyFiling();
            } else {
              this.modifyReSubmit();
            }
          }
        }
      }
    },
    // 提交申请
    async modifyFiling() {
      // let newEndTime = "";
      // if (this.duration) {
      //   newEndTime = this.formatDate(
      //     this.dataForm.newDt + " " + this.dataForm.newStartTime,
      //     this.duration
      //   );
      // }
      let params = {
        planId: this.$route.params.planId,
        operationType: this.dataForm.operationType,
        reason: this.dataForm.reason,
        newStartTime: this.dataForm.newDt + " " + this.dataForm.newStartTime,
        newDt: this.dataForm.newDt?.split("-").join(""),
        duration: this.duration,
      };

      const res = await modifyFiling({ ...params });
      if (res) {
        this.$router.push({
          name: "mentor-message",
          params: {
            type: 2,
          },
        });
        this.$toast("提交成功，班主任会尽快处理你的请假申请");
        // this.$router.go(-1);
        // wx.miniProgram.redirectTo({ url: "/pages/subscribe/index?seting=1" });
        // if (localStorage.getItem("message") == 1) {
        //   wx.miniProgram.redirectTo({ url: "/pages/subscribe/index?seting=1" });
        // } else {
        //   this.$router.go(-1);
        // }
      }
    },
    // 再次提交申请
    async modifyReSubmit() {
      // let newEndTime = "";
      // if (this.duration) {
      //   newEndTime = this.formatDate(
      //     this.dataForm.newDt + " " + this.dataForm.newStartTime,
      //     this.duration
      //   );
      // }
      let params = {
        planId: this.$route.params.planId,
        operationType: this.dataForm.operationType,
        reason: this.dataForm.reason,
        newStartTime: this.dataForm.newDt + " " + this.dataForm.newStartTime,
        newDt: this.dataForm.newDt?.split("-").join(""),
        duration: this.duration,
      };

      const res = await modifyReSubmit({ ...params });
      if (res) {
        // this.$router.go(-1);
        this.$router.push({
          name: "mentor-message",
          params: {
            type: 2,
          },
        });
        this.$toast("提交成功，班主任会尽快处理你的请假申请");
        // wx.miniProgram.redirectTo({ url: "/pages/subscribe/index?seting=1" });
        // if (localStorage.getItem("message") == 1) {
        //   wx.miniProgram.redirectTo({ url: "/pages/subscribe/index?seting=1" });
        // } else {
        //   this.$router.go(-1);
        // }
      }
    },
    async modifyDetail() {
      const res = await modifyDetail(this.$route.params.planId);
      if (res) {
        if (res.approvalStatus != 1) {
          this.dataForm.reason = res.reason;
        }
        if (res.operationType) {
          this.dataForm.operationType = res.operationType;
        }
        this.dataForm.approvalStatus = res.approvalStatus;
        this.dataForm.id = res.id;
        // this.dataForm.newDt = res.date;
        // this.dataForm.newStartTime = res.startTime;
        // this.dataForm.newEndTime = res.endTime;
        // this.time = res.week;

        if (
          res.approvalStatus != -1 &&
          res.approvalStatus != 1 &&
          res.newDateTime
        ) {
          this.dataForm.newDt = res.newDateTime.substring(0, 10);
          this.dataForm.newStartTime = res.newStartTime;
          this.dataForm.newEndTime = res.newEndTime;
          this.time = res.newWeek;
        }
        console.log(this.dataForm);
        this.$forceUpdate();
      }
    },
    formatDate(params, duration) {
      let date = new Date(params);
      let min = date.getMinutes();
      console.log(date);
      date.setMinutes(min + duration);
      console.log(min, date);
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    async modifyRecall(id) {
      const res = await modifyRecall(id);
      if (res) {
        this.$router.go(-1);
      }
    },
  },
  mounted() {},
  created() {
    this.getPlan();
    this.modifyDetail();
    this.getModifyFreeTime();
  },
  computed: {
    complete() {
      if (this.dataForm.operationType == 1) {
        return this.dataForm.newStartTime && this.dataForm.reason;
      } else {
        return this.dataForm.reason;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
textarea:disabled {
  background-color: #ccc;
  opacity: 0.4;
}

.leave_container {
  .info {
    background-color: #fff;

    ul {
      padding: 24px 0 0 0;

      li {
        line-height: 25px;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        color: #0E0E0E;
        padding: 0 15px;
      }

      span {
        display: block;
        width: 16px;
        height: 16px;
        margin: auto 0;
        margin-right: 6px;
        position: relative;
        top: -2px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .personal {
        width: 100%;
        word-wrap: none;
        line-height: 24px;
      }

      .title {
        color: #0E0E0E;
        font-weight: 600;
        line-height: 30px;
        font-size: 17px;
      }

      .approver {
        display: flex;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
        border-top: 1px solid #E4E4E4;
        padding: 0;
        margin-left: 15px;
        color: #0E0E0E;
        margin-top: 12px;

        p:nth-child(2) {
          color: #858585;
          padding-right: 15px;
        }
      }
    }
  }

  .leave_form {
    background-color: #fff;
    margin-top: 10px;

    ul {
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      padding: 11px 0;
      margin: 10px 0 0 0;
      background-color: #fff;

      li {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        display: flex;
        justify-content: space-between;
        padding: 11px 15px;
        align-items: center;

        .key {
          font-size: 12px;
          color: #858585;
          margin-top: 6px;
        }

        div {
          width: 255px;
          position: relative;
          box-sizing: border-box;
          border-radius: 4px;
          overflow: hidden;
        }

        button {
          outline: none;
          width: 102px;
          height: 38px;
          background-color: #fff;
          color: #E14247;
          border: 0.5px solid #E4E4E4;
        }

        button:nth-child(1) {
          border-radius: 4px 0px 0px 4px;
        }

        button:nth-child(2) {
          border-left: 0px;
          border-radius: 0px 4px 4px 0px;
        }

        .clickStyle {
          background: #E14247;
          color: #fff;
        }

        .one {
          border-radius: 4px !important;
        }

        .calendar {
          border: 0.5px solid #E4E4E4;
          height: 38px;
          border-radius: 4px;
          color: #858585;
          font-weight: 400;
          font-size: 14px;
          line-height: 38px;
          padding-left: 15px;

          img {
            width: 20px;
            height: 18px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .timeColor {
          color: #E14247;
        }

        textarea {
          width: 255px;
          height: 80px;
          border: 1px solid #EEEEEE;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          resize: none;
        }

        .star {
          color: #E14247;
        }
      }

      li:nth-child(1) {
        padding-bottom: 0;
      }

      li:nth-child(2) {
        padding-top: 0;
        line-height: 14px;
      }

      .line {
        height: 1px;
        background-color: #E4E4E4;
        padding: 0;
        margin: 11px 0 11px 15px;
      }
    }
  }

  .submit_btn {
    width: 345px;
    height: 45px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #CCCCCC;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    margin-top: 187px;
    border: 0;
  }

  .complete {
    background: #E14247;
    color: #fff;
  }

  .popup {
    min-height: 306px;

    h3 {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #0E0E0E;
      margin: 24px 0;
    }

    .timetable {
      padding: 0 15px;

      .timetable_list {
        max-height: 360px;
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
        overflow-y: scroll;
      }

      .timetable_list::-webket-scrollbar {
        width: 0 !important;
      }

      .item {
        width: 345px;
        height: 42px;
        border: 0.5px solid #E4E4E4;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        color: #0E0E0E;
        line-height: 42px;
        padding-left: 10px;
        box-sizing: border-box;
        margin: 12px 0;
        display: flex;
        justify-content: space-between;
        padding-right: 100px;

        span:nth-child(1) {
          min-width: 86px;
        }
      }

      .item_click {
        border: 0.5px solid #E14247;
        color: #E14247;
      }

      p {
        margin-top: 10px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #858585;
      }

      .btn_container {
        margin: 32px 0 44px 0;
        display: flex;
        justify-content: space-between;

        div {
          width: 168px;
          height: 48px;
          border-radius: 4px;
          border: 0.5px solid #E14247;
          color: #E14247;
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          line-height: 48px;
        }

        .determine_btn {
          background: #E14247;
          color: #fff;
        }
      }
    }

    .noMore {
      div {
        width: 220px;
        height: 48px;
        font-weight: 400;
        text-align: center;
        word-wrap: normal;
        color: #999999;
        margin: 60px auto;
        font-size: 14px;
      }

      .pop_button {
        width: 168px;
        height: 48px;
        background: #E14247;
        border-radius: 4px;
        display: block;
        margin: 0 auto;
        outline: none;
        border: 0;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
      }
    }
  }
}
</style>